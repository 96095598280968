<template>
  <div>
    <sdPageHeader :title="$t('welcome.title')"></sdPageHeader>
    <Main class="general-background">
      <p class="text-center w-75 mx-auto" style="font-size: 14px;">{{ $t("welcome.text") }}</p>
      <a-row v-if="authStore.config['youtube_videos_position'] === 0" :gutter="25" style="margin: 0;">
        <a-col :sm="24" :xs="24">
          <div style="height: 100%;">
            <videos-section></videos-section>
          </div>
        </a-col>
      </a-row>
      <a-row class="agenda-section">
        <p class="agenda-title">{{ $t("generic.titles.weekly_agenda") }}</p>
        <a-col :xs="24" style="max-height: 480px; overflow-y: auto;">
          <agenda-section></agenda-section>
        </a-col>
      </a-row>
      <a-row v-if="authStore.config['youtube_videos_position'] === 1" :gutter="25" style="margin: 0;">
        <a-col :sm="24" :xs="24">
          <div style="height: 100%;">
            <videos-section></videos-section>
          </div>
        </a-col>
      </a-row>
      <a-row style="margin-top: 3rem;">
        <a-col :xs="24">
          <faqs-section></faqs-section>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script setup>
import { watch, ref, onMounted } from "vue";
import { Main } from "../styled";
import { useAuthStore } from "@/stores/AuthStore";
import FaqsSection from "@/components/wiseral/home/faqsSection";
import VideosSection from "@/components/wiseral/home/videosSection";
import AgendaSection from "@/components/wiseral/home/agendaSection";

const authStore = useAuthStore();
const iframe = ref();

watch(
  () => iframe.value,
  (iframeValue) => {
    if (iframeValue) {
      iframeValue.addEventListener("load", () => {
        console.log("legendElement: ", iframeValue?.contentWindow?.document);
      });
    }
  }
);

onMounted(async () => {
  await authStore.getYoutubeLinks();
});

</script>

<style scoped lang="scss">

.agenda-section {
  overflow-x: auto;
  padding: 0 4px;
  margin-top: 3rem;
  max-width: 960px;
  .agenda-title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 16px;
  }  
}

:deep(.ant-page-header) {
  display: flex;
  justify-content: center;
  padding: 28px 4px 8px 4px;
  font-size: 16px;
  & .ant-page-header-heading-title {
    color: #02B388 !important;
  }
}
</style>