<template>
  <a-row class="faq-header">
    <label class="faq-title">
      FAQS
    </label>
    <sdButton
      size="small"
      type="primary"
      class="faq-section-btn"
      :class="{ 'active': faqSection === 'all' }"
      @click="faqSection = 'all'"
    >
      {{ $t('generic.buttons.all') }}
    </sdButton>
    <sdButton
      v-for="(section, index) in faqsStore.faqTypes"
      :key="`section-${index}`"
      size="small"
      type="primary"
      class="faq-section-btn"
      :class="{ 'active': faqSection === section }"
      @click="faqSection = section"
    >
      {{ $t(`faqs.types.${section}`, section) }}
    </sdButton>
  </a-row>
  <div style="position: relative; min-height: 250px;">
    <div class="loading-container" v-if="faqsStore.faqLoading">
      <a-spin class="loading-spinner"></a-spin>
    </div>
    <a-collapse v-model:activeKey="activeKey" ghost>
      <template #expandIcon="{ isActive }">
        <p v-if="isActive" style="font-size: 12px; color: inherit; text-align: center; font-family: none;">
          —
        </p>
        <p v-else style="font-size: 24px; color: inherit; text-align: center; font-family: none;">
          +
        </p>
      </template>
      <a-collapse-panel
        v-for="(faq, index) in faqsStore.faqs"
        :key="index"
        :header="locale === 'es' ? faq.title : faq.title_english"
      >
        <p>
          {{ locale === 'es' ? faq.body : faq.body_english }}
        </p>
      </a-collapse-panel>
    </a-collapse>
  </div>
  <div class="mt-4 mb-5 text-left">
    <sdButton
      size="small"
      type="secondary"
      class="view-all-button"
      @click="toggleAllorLess()"
    >
      {{ isAllFaqs ? $t("generic.titles.see_less_faqs") : $t("generic.titles.see_all_faqs") }}
    </sdButton>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from "vue";
import { useFaqsStore } from "../../../stores/FaqsStore";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const faqsStore = useFaqsStore();
const activeKey = ref([]);
const isAllFaqs = ref(false);
const faqSection = ref('all');

const toggleAllorLess = async () => {
  isAllFaqs.value = !isAllFaqs.value;
};

watch(() => isAllFaqs.value, async (newValue) => {
  await faqsStore.getFaqs(faqSection.value, newValue ? 0 : 5);
});

watch(() => faqSection.value, async (newValue) => {
  await faqsStore.getFaqs(newValue, isAllFaqs.value ? 0 : 5);
});

onMounted(async () => {
  await faqsStore.getFaqTypes();
  await faqsStore.getFaqs();
});
</script>
<style scoped lang="scss">

.faq-header {
  border-bottom: 1px solid #02B388;
  align-items: center;
  padding-bottom: 1rem;
  gap: 8px;
  & .faq-title {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: #02B388;
    margin-right: 24px;
  }
  & .faq-section-btn {
    font-size: 12px;
    padding: 4px 12px !important;
    border-radius: 25px !important;
    background-color: transparent !important;
    height: fit-content !important;
    border: 1px solid #08A07D;
    color: #08A07D;
    &:hover {
      background-color: #08A07D !important;
      color: white !important;
      border: none;
    }
    &.active {
      background-color: #08A07D !important;
      color: white !important;
      border: none;
    }
  }
}
.view-all-button {
  width: 12rem;
  border-radius: 24px !important;  
}
.loading-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.1);
}
.ant-collapse {
  border: none;
  border-radius: 0px;
  background-color: transparent;
  padding-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .ant-collapse {
    text-align: center !important;
  }
}
:deep(.ant-collapse-item) {
  border-bottom: 1px solid #02B388 !important;
  padding: 8px 0;
  border-radius: 0px !important;
  & .ant-collapse-header {
    text-align: left;
    color: #02B388 !important;
    background-color: transparent !important;
    font-size: 18px;
    font-weight: medium;
    &:hover {
      color: #004851 !important;
    }
  }
  &.ant-collapse-item-active {
    & .ant-collapse-header {
      color: #004851 !important;
    }
  }
}
:deep(.ant-collapse-content) {
  background-color: transparent;
  border: none;
  text-align: left;
  & .ant-collapse-content-box {
    padding: 8px !important;
  }
  & p {
    color: inherit;
  }
}
</style>