<template>
    <template v-if="authStore.agendaLoading">
      <div class="loading-container">
        <a-spin class="loading-spinner"></a-spin>
      </div>
    </template>
    <template v-else>
      <a-row class="agenda-table">
        <a-col :xs="24">
          <a-row class="event-row border-t">
            <a-col :xs="3">
              {{ $t('agenda.hour') }}
            </a-col>
            <a-col :xs="3">
              {{ $t('agenda.region') }}
            </a-col>
            <a-col :xs="7">
              {{ $t('agenda.event') }}
            </a-col>
            <a-col :xs="3">
              {{ $t('agenda.actual') }}
            </a-col>
            <a-col :xs="4">
              {{ $t('agenda.forecast') }}
            </a-col>
            <a-col :xs="4">
              {{ $t('agenda.previous') }}
            </a-col>
          </a-row>
          <a-row
            v-for="(dateItem, index) in groupedAgenda"
            :key="`date-item-${index}`"
          >
            <a-col :xs="24" class="date-key-row">
              {{ d(new Date(moment(dateItem[0].dateKey)), 'medium') }}
            </a-col>
            <a-col :xs="24">
              <a-row
                v-for="(eventItem, index) in dateItem" class="event-row" :class="{ 'border-b': index < dateItem?.length - 1 }"
                :key="`event-item-${index}`"
              >
                <a-col :xs="3">
                  {{ moment(eventItem?.release_date).format('HH:mm') }}
                </a-col>
                <a-col :xs="3" style="text-transform: uppercase;">
                  {{ eventItem?.region || '' }}
                </a-col>
                <a-col :xs="7">
                  {{ eventItem?.key || '' }}
                </a-col>
                <a-col :xs="3">
                  {{ eventItem?.value || '' }}
                </a-col>
                <a-col :xs="4">
                  {{ eventItem?.forecast || '' }}
                </a-col>
                <a-col :xs="4">
                  {{ eventItem?.previous || '' }}
                </a-col>  
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>
  </template>
  <script setup>
  import { computed, onMounted } from 'vue';
  import { useI18n } from "vue-i18n";
  import { useAuthStore } from '@/stores/AuthStore';
  import moment from "moment";
  const { d } = useI18n();
  const authStore = useAuthStore();
  const groupedAgenda = computed(() => authStore.agendaData.reduce((acc, obj) => {
      const dateKey = moment(obj?.release_date).format('YYYY-MM-DD');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push({ ...obj, dateKey });
      return acc;
    }, {})
  );
  onMounted(async () => {
    await authStore.getWeeklyAgenda();
  });
  </script>
  <style scoped lang="scss">
  @import "~@/static/css/colors.scss";
  
  .loading-container {
    position: relative;
    width: 100%;
    height: 400px;
    .loading-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  .agenda-table {
    font-size: 14px;
    min-width: 800px;
    overflow-x: auto;
    .date-key-row {
      padding: 8px;
      color: white;
      background-color: $text-light-color;
      font-size: 16px;
    }  
    .event-row {
      padding: 12px;
      &.border-t {
        border-top: 1px solid;
      }
      &.border-b {
        border-bottom: 1px solid;
      }
    }
  }
</style>