<template>
   <p class="video-title">{{ $t("welcome.video_title") }}</p>
  <div class="video-list">
    <Iframe
      v-for="(link, index) in authStore.youtubeLinks"
      :key="index"
      :link="link?.url"
      :description="locale === 'es' ? link?.description_spanish : link?.description"
      :title="locale === 'es' ? link?.title_spanish : link?.title"
      width="410px" 
      height="216px"
    />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Iframe from "@/components/iframe/Iframe.vue";
import { useAuthStore } from '../../../stores/AuthStore';

const authStore = useAuthStore();
const { locale } = useI18n();

onMounted(async () => {
  await authStore.getYoutubeLinks();
});
</script>
<style scoped lang="scss">
.video-title {
  text-align: left;
  margin-top: 3rem;
  padding-left: 8px;
  font-weight: bold;
}
.video-list {
  width: 100%;
  min-height: 216px;
  display: flex;
  justify-content: left;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 32px;
}
</style> 