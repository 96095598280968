import { defineStore } from "pinia";
// import axios from "axios"
import client from "@/plugins/axios";

export const useFaqsStore = defineStore("useFaqsStore", {
  state: () => {
    return {
      faqs: [],
      faqLoading: true,
      faqTypes: []
    };
  },
  actions: {
    async getFaqs(section = 'all', limit = 5) {
      try {
        this.faqLoading = true;
        return client
        .get(`/faqs?section=${section}&limit=${limit}`)
          .then((response) => {
            this.faqs = response.data;
            this.faqLoading = false;
          });
      } catch (err) {
        this.faqLoading = false;
        return (this.error = err);
      }
    },
    async getFaqTypes() {
      try {
        this.faqLoading = true;
        return client
          .get('/faq_types')
          .then((response) => {
            this.faqTypes = response.data;
            this.faqLoading = false;
          });
      } catch (err) {
        this.faqLoading = false;
        return (this.error = err);
      }
    },
  
    },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "config",
        storage: localStorage,
      },
    ],
  },
});